<template>
	<div>
		<div class="form-group c_form_group c_form_group_deck p-2">
			<label class="font-15" v-show="label != null" v-html="label"></label>
			<div class="input-group">
				<textarea class="form-control" aria-label="With textarea" :style="height ? ('height:'+ height +'px') : 'height: 400px;'" style="margin-right: 20px;" :placeholder="placeholder" v-model="text" @input="replaceEmojis" @focus="focusS" @keyup.enter="enterPress"></textarea>
				<img src="@/assets/images/1f600.png" class="button_input_smile" @click="toogleEmoji" />
			</div>
		</div>

		<picker @select="onSelectEmoji" :showPreview="false" :showSearch="false" style="position: fixed; z-index: 999;" :style="css_picker" v-show="mostrar_picker_emoji" class="picker_emoji_p" :data="emojiIndex" set="twitter" />
	</div>
</template>

<script>

// npm install --save emoji-mart-vue-fast
// https://www.npmjs.com/package/emoji-mart-vue-fast

import "emoji-mart-vue-fast/css/emoji-mart.css"
import data from "emoji-mart-vue-fast/data/all.json"
import { Picker, EmojiIndex } from "emoji-mart-vue-fast"

let emojiIndex = new EmojiIndex(data);

export default {
	name: 'TextAreaDeck',
	props: ["model", "label", "placeholder", "position_emoji", "height"],
	data: function () {
		return {
			emojiIndex: emojiIndex,
			text: "",
			mostrar_picker_emoji: false,
			css_picker: ""
		}
	},
	watch: {
		text: function (value) {
			if (value) this.$emit("attValor", value)
		},
		model: function (value) {
			this.text = value;
		}
	},
	components: {
		Picker
	},
	methods: {
		enterPress : function () {
			this.$emit('enterPress')
		},
		onSelectEmoji : function (emoji) {
			this.text = this.text + emoji.native
		},
		replaceEmojis : function () {
			const emojiRegex = /:[^:\s]*(?:::[^:\s]*)*:/g;
			this.text = this.text.replace(emojiRegex, (match) => {
				const emoji = match.replace(/:/g, '');
				return this.getEmoji(emoji)
			});
		},
		getEmoji : function (name) {
			if (emojiIndex.search(name)[0] != undefined) {
				return emojiIndex.search(name)[0].native;
			} else {
				return name
			}
		},
		focusS : function () {
			this.mostrar_picker_emoji = false
		},
		toogleEmoji : function (e) {
			var left = e.target.x - 315
			let top = e.target.y + 20

			if (this.position_emoji != undefined) {
				top = top - 450
			}

			this.css_picker = "top:" + top + "px; left: " + left + "px;"
			this.mostrar_picker_emoji = this.mostrar_picker_emoji ? false : true
		},
		pickerClose : function () {
			this.mostrar_picker_emoji = false
		}
	},
	mounted() {
		this.text = this.model;
	},
	created() {
		window.addEventListener('scroll', this.pickerClose);
	},
	unmounted() {
		window.removeEventListener('scroll', this.pickerClose);
	}
}

</script>